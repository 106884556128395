import React, { useState, useEffect, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import { useAuth } from "../../context/auth.js";
import { Helmet } from "react-helmet";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import "../../App.css";

import io from "socket.io-client";
import AdminSideBar from "../../components/sidebar/AdminSideBar.js";
import NavbarHeader from "../../components/header/NavbarHeader.js";
import Footer from "../../components/footer/Footer.jsx";
const API_URL = process.env.REACT_APP_API || "http://localhost:7000";

const Message = () => {
  const [formData, setFormData] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAll, setShowAll] = useState(false); // To toggle between 5 and all notifications

  const notifListRef = useRef(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/notifications`
        );
        console.log("Fetched notifications:", response.data); // Debugging
        setNotifications(response.data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();

    const fetchFormData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/form/formsz`
        );
        console.log("API RES: ", response);
        console.log("Fetched form data:", response.data);
        setFormData(response.data);
        setFormData(response.data.reverse());
      } catch (error) {
        console.error("Error fetching form data:", error);
      }
    };

    fetchNotifications();
    fetchFormData();

    const socket = io(API_URL);
    socket.on("newUserNotification", (notif) => {
      console.log("New notification:", notif);
      setNotifications((prevNotifications) => [...prevNotifications, notif]);
    });

    return () => {
      socket.off("newUserNotification");
    };
  }, []);

  useEffect(() => {
    if (showAll && notifListRef.current) {
      notifListRef.current.scrollTop = notifListRef.current.scrollHeight;
    }
  }, [showAll, notifications]);

  const handleDropdownToggle = async () => {
    if (showDropdown) {
      try {
        await axios.post(
          `${process.env.REACT_APP_API}/api/v1/notifications/mark-as-read`
        );
        setNotifications((prevNotifications) =>
          prevNotifications.map((notif) => ({ ...notif, read: true }))
        );
      } catch (error) {
        console.error("Error marking notifications as read:", error);
      }
    }
    setShowDropdown(!showDropdown);
  };

  const deleteFormData = async (id, name, createdAt) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this entry?"
    );
    if (!confirmed) {
      return; // Exit the function if the user does not confirm
    }

    try {
      await axios.delete(
        `${process.env.REACT_APP_API}/api/v1/form//forms/${id}`
      );
      setFormData((prevFormData) =>
        prevFormData.filter((item) => item._id !== id)
      );

      // Delete associated notification
      await axios.post(
        `${process.env.REACT_APP_API}/api/v1/notifications/notifications/delete`,
        {
          message: name,
          time: new Date(createdAt).toLocaleTimeString(),
        }
      );

      toast.success("Message and notification deleted");
    } catch (error) {
      console.error("Error deleting form data:", error);
      toast.error("Failed to delete entry");
    }
  };

  const displayedNotifications = showAll
    ? notifications
    : notifications.slice(0, 4);

  const [auth, setAuth] = useAuth();
  const handlelogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout successfully");
  };

  return (
    <>
      <div>
        <Helmet>
          <title>Admin-Dashboard</title>
        </Helmet>
      </div>
      <div className="wrapper">
        {/* Sidebar */}
        <AdminSideBar />
        {/* End Sidebar */}
        <div className="main-panel">
          <NavbarHeader />
          <div className="container">
            <div className="page-inner">
              <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row pt-2 pb-4">
                <div>
                  <h3 className="fw-bold mb-3">Dashboard</h3>
                  <h6 className="op-7 mb-2">Welcome to Admin Dashboard</h6>
                </div>
              </div>
              <div className="total-entries">
                <h4>Total Messages: {formData.length}</h4>
              </div>
              <div className="form-data-grid">
                {formData.map((item) => (
                  <div className="form-data-item" key={item._id}>
                    <h3>{item.name}</h3>
                    <p>Email: {item.email}</p>
                    <p>Subject: {item.subject}</p>
                    <p>Message: {item.message}</p>
                    <span>{new Date(item.createdAt).toLocaleString()}</span>
                    <button
                      className="btn btn-danger"
                      onClick={() =>
                        deleteFormData(item._id, item.name, item.createdAt)
                      }
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Message;
