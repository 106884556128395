import React from "react";
import Logo from "../../images/logotv.png";

const LogoComponent = () => {
  return (
    <>
      <div className="logo-container">
        <div className="logo-img">
          <img src={Logo} alt="logo" />
        </div>
        <div className="comp-logo-heading">SUPER TV</div>
      </div>
    </>
  );
};

export default LogoComponent;
