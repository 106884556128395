import React, { useState, useEffect } from "react";

import { useAuth } from "../../context/auth.js";
import { useParams, NavLink, Link } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

import Modal from "antd/es/modal/Modal.js";
import Departform from "../../components/form/Depart.js";
import { Helmet } from "react-helmet";

const EnterStoreData = () => {
  const { slug } = useParams();
  const [storeName, setStoreName] = useState("");
  const [departs, setDeparts] = useState([]);
  const [name, setName] = useState("");
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [updatedName, setUpdatedName] = useState("");
  const [storeId, setStoreId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [departmentsPerPage] = useState(10); // Number of departments per page

  // Fetch store name and ID based on slug
  useEffect(() => {
    const fetchStoreName = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/store/single-store/${slug}`
        );
        if (response.data.success) {
          setStoreName(response.data.Store.name);
          setStoreId(response.data.Store._id);
        } else {
          setStoreName("Unknown Store");
        }
      } catch (error) {
        console.error(error);
        setStoreName("Unknown Store");
      }
    };

    fetchStoreName();
  }, [slug]);

  // Fetch all departments when storeId is set
  useEffect(() => {
    if (storeId) {
      getAllDeparts();
    }
  }, [storeId]);

  // Fetch all departments for the given store
  const getAllDeparts = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/depart/get-depart/${storeId}`
      );
      if (data.success) {
        setDeparts(data.Depart);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in getting departments");
    }
  };

  // Handle department creation
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/depart/create-depart`,
        {
          name,
          storeId,
        }
      );
      if (data?.success) {
        toast.success(`${name} is Created`);
        getAllDeparts(); // Refresh department list
        setName(""); // Clear the input field after creating the department
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong in Creating department");
      }
    }
  };

  // Handle department update
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API}/api/v1/depart/update-depart/${selected._id}`,
        { name: updatedName, storeId }
      );
      if (data.success) {
        toast.success(data.message);
        setSelected(null);
        setUpdatedName("");
        setVisible(false);
        getAllDeparts(); // Refresh department list
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  // Handle department deletion
  const handleDelete = async (pId) => {
    try {
      let answer = window.confirm(
        "Are you sure you want to delete this department?"
      );
      if (!answer) return;
      const { data } = await axios.delete(
        `${process.env.REACT_APP_API}/api/v1/depart/delete-depart/${pId}`
      );
      if (data.success) {
        toast.success(data.message);
        getAllDeparts();
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  // Logic for pagination
  const indexOfLastDepartment = currentPage * departmentsPerPage;
  const indexOfFirstDepartment = indexOfLastDepartment - departmentsPerPage;
  const currentDepartments = departs.slice(
    indexOfFirstDepartment,
    indexOfLastDepartment
  );

  // Calculate department number based on current page
  const getDepartmentNumber = (index) => {
    return index + 1 + (currentPage - 1) * departmentsPerPage;
  };

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [auth, setAuth] = useAuth();
  const handlelogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout successfully");
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    console.log("Toggle button clicked");
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <>
      <div>
        <Helmet>
          <title>Store-Departments</title>
        </Helmet>
      </div>
      <div className="wrapper">
        {/* Sidebar */}
        <div
          id="sidebar"
          className={`sidebar ${isExpanded ? "sidebar-expanded" : ""}`}
          data-background-color="dark"
        >
          <div className="sidebar-logo">
            {/* Logo Header */}
            <div className="logo-header" data-background-color="dark">
              <Link href="#a" className="logo">
                <p id="mia">🛒 Super TV Advertisement</p>
              </Link>
              <div className="nav-toggle">
                <button className="btn btn-toggle toggle-sidebar">
                  <i className="gg-menu-right" />
                </button>
                <button
                  className="btn btn-toggle sidenav-toggler "
                  onClick={handleToggle}
                >
                  <i className={`gg-menu-${isExpanded ? "left" : "right"}`} />
                </button>
              </div>
              <button className="topbar-toggler more">
                <i className="gg-more-vertical-alt" />
              </button>
            </div>
            {/* End Logo Header */}
          </div>
          <div className="sidebar-wrapper scrollbar scrollbar-inner">
            <div className="sidebar-content">
              <ul className="nav nav-secondary">
                <li className="nav-item active">
                  <Link to="">
                    <i className="fas fa-home" />
                    <p>Dashboard</p>
                  </Link>
                </li>
                <li className="nav-section">
                  <span className="sidebar-mini-icon">
                    <i className="fa fa-ellipsis-h" />
                  </span>
                  <h4 className="text-section">Components</h4>
                </li>
                <li className="nav-item">
                  <a data-bs-toggle="collapse" href="#base">
                    <i className="fas fa-layer-group" />
                    <p>Users</p>
                    <span className="caret" />
                  </a>
                  <div className="collapse" id="base">
                    <ul className="nav nav-collapse">
                      <li>
                        <a href="components/buttons.html">
                          <Link to={`/user/${slug}`}>Register User</Link>
                        </a>
                      </li>
                      <li>
                        <a href="components/buttons.html">
                          <Link to={`/alluser/${slug}`}>All User</Link>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <a href={`/store/${slug}`}>
                    <i className="fas fa-th-list" />
                    <NavLink onClick={handlelogout} to={`/store/${slug}`}>
                      LOGOUT
                    </NavLink>
                    <Toaster />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Sidebar */}
        <div className="main-panel">
          <div className="main-header">
            <div className="main-header-logo">
              {/* Logo Header */}
              <div className="logo-header" data-background-color="dark">
                <a href="index.html" className="logo">
                  <img
                    src="assets/img/kaiadmin/logo_light.svg"
                    alt="navbar brand"
                    className="navbar-brand"
                    height={20}
                  />
                </a>
                <div className="nav-toggle">
                  <button className="btn btn-toggle toggle-sidebar">
                    <i className="gg-menu-right" />
                  </button>
                  <button
                    className="btn btn-toggle sidenav-toggler"
                    onClick={handleToggle}
                  >
                    <i className={`gg-menu-${isExpanded ? "left" : "right"}`} />
                  </button>
                </div>
                <button className="topbar-toggler more">
                  <i className="gg-more-vertical-alt" />
                </button>
              </div>
              {/* End Logo Header */}
            </div>
            {/* Navbar Header */}
            <nav className="navbar navbar-headers navbar-header-transparent navbar-expand-lg border-bottom">
              <div className="container-fluid">
                <nav className="navbar navbar-header-left navbar-expand-lg navbar-form nav-search p-0 d-none d-lg-flex">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button type="submit" className="btn btn-search pe-1">
                        <i className="fa fa-search search-icon" />
                      </button>
                    </div>
                    <input
                      type="text"
                      placeholder="Search ..."
                      className="form-control"
                    />
                  </div>
                </nav>
                <ul className="navbar-nav topbar-nav ms-md-auto align-items-center">
                  <li className="nav-item topbar-icon dropdown hidden-caret d-flex d-lg-none">
                    <a
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                      href="#aq"
                      role="button"
                      aria-expanded="false"
                      aria-haspopup="true"
                    >
                      <i className="fa fa-search" />
                    </a>
                    <ul className="dropdown-menu dropdown-search animated fadeIn">
                      <form className="navbar-left navbar-form nav-search">
                        <div className="input-group">
                          <input
                            type="text"
                            placeholder="Search ..."
                            className="form-control"
                          />
                        </div>
                      </form>
                    </ul>
                  </li>

                  <li className="nav-items topbar-user ">
                    <Link
                      className=" profile-pic"
                      href="#aq"
                      aria-expanded="false"
                    >
                      <span className="profile-username">
                        <span className="op-7">Hi,</span>
                        <span className="bold">Admin</span>
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
            {/* End Navbar */}
          </div>
          <div className="container">
            <div className="page-inner">
              <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row pt-2 pb-4">
                <div>
                  <h3 className="fw-bold mb-3">Dashboard</h3>
                  <h6 className="op-7 mb-2">Welcome to {storeName}</h6>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="card card-round">
                    <div className="card-body">
                      <div className="card-head-row card-tools-still-right">
                        <div className="card-title">Create Department</div>
                        <div className="card-tools">
                          <div className="dropdown">
                            <button
                              className="btn btn-icon btn-clean me-0"
                              type="button"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            ></button>
                          </div>
                        </div>
                      </div>
                      <div className="card-list py-4">
                        <div className="item-list">
                          <div className="info-user ms-3">
                            <Departform
                              handleSubmit={handleSubmit}
                              value={name}
                              setValue={setName}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card card-round">
                    <div className="card-body">
                      <div className="card-head-row card-tools-still-right">
                        <div className="card-title">Total Departments</div>
                        <div className="card-tools">
                          <div className="dropdown">
                            <button
                              className="btn btn-icon btn-clean me-0"
                              type="button"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            ></button>
                          </div>
                        </div>
                      </div>
                      <div className="card-list ">
                        <div className="item-list">
                          <div className="info-user ">
                            <p id="kis">{departs.length}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="card card-round">
                    <div className="card-header">
                      <div className="card-head-row card-tools-still-right">
                        <div className="card-title">Department Details</div>
                        <div className="card-tools">
                          <div className="dropdown">
                            <button
                              className="btn btn-icon btn-clean me-0"
                              type="button"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table align-items-center mb-0">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Created Date</th>
                              <th scope="col">Your Departments</th>
                              <th scope="col">Images</th>
                              <th scope="col">Videos</th>
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentDepartments.map((d, index) => (
                              <tr key={d._id}>
                                <td id="koor">{getDepartmentNumber(index)}</td>
                                <td id="koor">
                                  {new Date(d.createdAt).toLocaleDateString()}
                                </td>
                                <td className="li">
                                  <NavLink
                                    to={`/departimage/${slug}/${d.slug}`}
                                  >
                                    {d.name}
                                  </NavLink>
                                </td>
                                <td className="li" id="koor">
                                  {d.imageCount}
                                </td>
                                <td className="li" id="koor">
                                  {d.videoCount}
                                </td>
                                <td>
                                  <button className="btn btn-secondary ms-2">
                                    <NavLink
                                      to={`/departimage/${slug}/${d.slug}`}
                                      style={{ color: "white" }}
                                    >
                                      View Department
                                    </NavLink>
                                  </button>
                                  <button
                                    className="btn btn-primary ms-2"
                                    onClick={() => {
                                      setVisible(true);
                                      setUpdatedName(d.name);
                                      setSelected(d);
                                    }}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    className="btn btn-danger ms-2"
                                    onClick={() => handleDelete(d._id)}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <nav>
                          <ul className="pagination justify-content-center">
                            {[
                              ...Array(
                                Math.ceil(departs.length / departmentsPerPage)
                              ).keys(),
                            ].map((number) => (
                              <li
                                key={number}
                                className={`page-item ${
                                  currentPage === number + 1 ? "active" : ""
                                }`}
                              >
                                <button
                                  onClick={() => paginate(number + 1)}
                                  className="page-link"
                                >
                                  {number + 1}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </div>
                    </div>
                    <Modal
                      onCancel={() => setVisible(false)}
                      footer={null}
                      visible={visible}
                    >
                      <Departform
                        value={updatedName}
                        setValue={setUpdatedName}
                        handleSubmit={handleUpdate}
                      />
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="footer">
            <div className="container-fluid d-flex justify-content-between">
              <nav className="pull-left">
                <ul className="nav">
                  <li className="nav-item"></li>
                  <li className="nav-item">
                    <Link className="nav-link" href="#a">
                      {" "}
                      Help{" "}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" href="#a">
                      {" "}
                      Licenses{" "}
                    </Link>
                  </li>
                </ul>
              </nav>
              <div className="copyright">
                2024, made with <i className="fa fa-heart heart text-danger" />{" "}
                by
                <a href="https://digitalhelpltd.com/"> Digital Help Ltd</a>
              </div>
              <div>
                Distributed by
                <Link href="#a"> E Networks</Link>.
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default EnterStoreData;
