import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import toast from "react-hot-toast";

import { useAuth } from "../context/auth.js";
import { Helmet } from "react-helmet";
import axios from "axios";
import Footer from "../components/footer/Footer.jsx";
import AdminSideBar from "../components/sidebar/AdminSideBar.js";
import NavbarHeader from "../components/header/NavbarHeader.js";

const Temp = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/auth/users`
        );
        if (response.data.success) {
          setUsers(response.data.users);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API}/api/v1/auth/users/${id}`
      );
      if (response.data.success) {
        setUsers(users.filter((user) => user._id !== id));
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const [auth, setAuth] = useAuth();
  const handlelogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout successfully");
  };
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    console.log("Toggle button clicked");
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <>
      <div>
        <Helmet>
          <title>All Users</title>
        </Helmet>
      </div>
      <div className="wrapper">
        {/* Sidebar */}
        <AdminSideBar />
        <div className="main-panel">
          <NavbarHeader />
          <div className="container">
            <div className="page-inner">
              <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row pt-2 pb-4">
                <div>
                  <h3 className="fw-bold mb-3">Dashboard</h3>
                  <h6 className="op-7 mb-2">Welcome Admin</h6>
                </div>
              </div>
              {/* ============================================================== */}

              <div className="users-list">
                <h4>Registered Users : {users.length}</h4>

                <div className="card-containers">
                  {users.map((user) => (
                    <div className="cards" key={user._id}>
                      <h4>{user.name}</h4>
                      <p>Store Name: {user.address}</p>
                      <p>Email: {user.email}</p>
                      <p>Phone: {user.phone}</p>
                      <button onClick={() => handleDelete(user._id)}>
                        Delete
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Temp;
