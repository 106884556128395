import React, { useState } from "react";
import { useParams, NavLink, Link } from "react-router-dom";
import { useAuth } from "../../context/auth.js";
import toast from "react-hot-toast";

const AdminSideBar = () => {
  const { storeSlug } = useParams();

  const [auth, setAuth] = useAuth();

  const handlelogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout successfully");
  };
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    console.log("Toggle button clicked");
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <>
      {/* Sidebar */}
      <div
        id="sidebar"
        className={`sidebar ${isExpanded ? "sidebar-expanded" : ""}`}
        data-background-color="dark"
      >
        <div className="sidebar-logo">
          {/* Logo Header */}
          <div className="logo-header" data-background-color="dark">
            <Link href="#a" className="logo">
              <p id="mia">🛒 SUPER TV Advertisement</p>
            </Link>
            <div className="nav-toggle">
              <button className="btn btn-toggle toggle-sidebar">
                <i className="gg-menu-right" />
              </button>
              <button
                className="btn btn-toggle sidenav-toggler "
                onClick={handleToggle}
              >
                <i className={`gg-menu-${isExpanded ? "left" : "right"}`} />
              </button>
            </div>
            <button className="topbar-toggler more">
              <i className="gg-more-vertical-alt" />
            </button>
          </div>
          {/* End Logo Header */}
        </div>
        <div className="sidebar-wrapper scrollbar scrollbar-inner">
          <div className="sidebar-content">
            <ul className="nav nav-secondary">
              <li className="nav-item active">
                <a href={`/enterdata/${storeSlug}`}>
                  <i className="fas fa-home" />
                  <p>Dashboard</p>
                </a>
              </li>
              <li className="nav-section">
                <span className="sidebar-mini-icon">
                  <i className="fa fa-ellipsis-h" />
                </span>
                <h4 className="text-section">Components</h4>
              </li>

              <li className="nav-item">
                <a href={`/store/${storeSlug}`}>
                  <i className="fas fa-th-list" />
                  <NavLink onClick={handlelogout} to={`/store/${storeSlug}`}>
                    LOGOUT
                  </NavLink>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSideBar;
