import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container-fluid d-flex justify-content-between">
          <nav className="pull-left">
            <ul className="nav">
              <li className="nav-item"></li>
              <li className="nav-item">
                <Link className="nav-link" href="#a">
                  Help
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#a">
                  Licenses
                </Link>
              </li>
            </ul>
          </nav>
          <div className="copyright">
            2024, made with <i className="fa fa-heart heart text-danger" /> by
            <a href="https://digitalhelpltd.com/"> Digital Help Ltd</a>
          </div>
          <div>
            Distributed by
            <Link to="#a"> E! Networks</Link>.
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
