import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Helmet } from "react-helmet";

const DisplayFiles = () => {
  const { storeSlug, departSlug } = useParams();
  const [files, setFiles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [delayAfterVideo, setDelayAfterVideo] = useState(0);
  const videoRefs = useRef([]);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const fileType = query.get("fileType");
  const imageInterval = parseInt(query.get("imageInterval"), 10) || 1500;
  const videoInterval = parseInt(query.get("videoInterval"), 10) || 1500;

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/store/selectedfiles/${storeSlug}/${departSlug}`
        );
        let files = response.data.files;

        if (fileType === "images") {
          files = files.filter((file) =>
            file.filePath.match(/\.(jpg|jpeg|png|svg)$/i)
          );
        } else if (fileType === "videos") {
          files = files.filter((file) => file.filePath.match(/\.(mp4|webm)$/i));
        }

        setFiles(files);
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    fetchFiles();
  }, [storeSlug, departSlug, fileType]);

  useEffect(() => {
    let timer;
    if (!videoPlaying) {
      timer = setInterval(
        () => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % files.length);
        },
        fileType === "images" ? imageInterval : videoInterval
      );
    }

    return () => clearInterval(timer);
  }, [
    currentIndex,
    videoInterval,
    videoPlaying,
    fileType,
    imageInterval,
    files.length,
  ]);

  const handleChange = (index) => {
    setCurrentIndex(index);
  };

  const handleVideoEnd = () => {
    setVideoPlaying(false);
    setDelayAfterVideo(0);
  };

  useEffect(() => {
    if (fileType === "videos") {
      videoRefs.current.forEach((video, index) => {
        if (video) {
          if (index === currentIndex) {
            video.pause();
            video.currentTime = 0;
            video.play().catch((error) => {
              console.log("Video playback error: ", error);
            });
            setVideoPlaying(true);
          } else {
            video.pause();
          }
        }
      });
    }
  }, [currentIndex, fileType]);

  useEffect(() => {
    if (files.length === 1 && fileType === "videos") {
      const firstVideo = videoRefs.current[0];
      if (firstVideo) {
        // Pause and reset first video before playing
        firstVideo.pause();
        firstVideo.currentTime = 0;
        firstVideo.play().catch((error) => {
          console.log("Video playback error: ", error);
        });
        setVideoPlaying(true);
      }
    }
  }, [files, fileType]);

  useEffect(() => {
    const adjustVideoInterval = () => {
      if (fileType === "videos" && videoRefs.current[currentIndex]) {
        const video = videoRefs.current[currentIndex];
        video.addEventListener(
          "loadedmetadata",
          () => {
            const duration = video.duration * 1000;
            setDelayAfterVideo(duration || 3000);
          },
          { once: true }
        );
      }
    };

    adjustVideoInterval();
  }, [currentIndex, fileType]);

  useEffect(() => {
    if (delayAfterVideo > 0) {
      const delayTimer = setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % files.length);
        setDelayAfterVideo(0);
      }, delayAfterVideo);

      return () => clearTimeout(delayTimer);
    }
  }, [delayAfterVideo, currentIndex, files.length]);

  return (
    <>
      <div>
        <Helmet>
          <title>Store-Ads</title>
        </Helmet>
      </div>

      <div className="carousel-container">
        {files.length > 0 ? (
          <div className="half-screen">
            <Carousel
              selectedItem={currentIndex}
              onChange={handleChange}
              autoPlay={false}
              infiniteLoop
              showThumbs={false}
              showStatus={false}
              className="file-carousel"
              interval={fileType === "images" ? imageInterval : 3000}
              swipeable={false}
              showArrows={false}
            >
              {files.map((file, index) => (
                <div key={file._id} className="file-item">
                  {file.filePath.match(/\.(jpg|jpeg|png|svg)$/i) ? (
                    <img
                      src={`${process.env.REACT_APP_API}/${file.filePath}`}
                      alt="Uploaded file"
                    />
                  ) : file.filePath.match(/\.(mp4|webm)$/i) ? (
                    <video
                      ref={(el) => (videoRefs.current[index] = el)}
                      id={`video-${index}`}
                      controls
                      className="video-player"
                      onEnded={handleVideoEnd}
                      onPlay={() => setVideoPlaying(true)}
                      onPause={() => setVideoPlaying(false)}
                      autoPlay={files.length === 1}
                      loop={files.length === 1}
                    >
                      <source
                        src={`${process.env.REACT_APP_API}/${file.filePath}`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : null}
                </div>
              ))}
            </Carousel>
          </div>
        ) : (
          <h1>No files found for this department.</h1>
        )}
      </div>
    </>
  );
};

export default DisplayFiles;
