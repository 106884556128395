import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";

const RegisterGetStore = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [phoneError, setPhoneError] = useState(""); // State for phone validation error
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/store/get-store`
        );
        if (res.data.success) {
          setStores(res.data.Store);
        } else {
          toast.error("Failed to fetch stores");
        }
      } catch (error) {
        console.error("Error fetching stores:", error);
        toast.error("Something went wrong while fetching stores");
      }
    };

    fetchStores();
  }, []);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    // Check if the password is at least 8 characters long
    if (newPassword.length >= 8) {
      setPassword(newPassword);
      setPasswordError("");
    } else {
      setPassword(newPassword); // Update the state even if it's invalid
      setPasswordError("Password must be at least 8 characters long.");
    }
  };

  const handlePhoneChange = (e) => {
    const newPhone = e.target.value;
    // Validate for digits and symbols (+, -, (, ))
    if (/^[\d+\-()]*$/.test(newPhone)) {
      setPhone(newPhone);
      setPhoneError("");
    } else {
      setPhoneError("Phone number can only contain digits and symbols + - ( )");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (passwordError || phoneError) {
      toast.error(passwordError || phoneError);
      return;
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/auth/register`,
        {
          name,
          email,
          password,
          phone,
          address: selectedStore,
          role: 0,
        }
      );

      if (res && res.data.success) {
        toast.success(res.data.message);
        navigate(`/dashboard`);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      // Handle the error properly
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
      console.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Register Now</title>
      </Helmet>

      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <Link to={`/dashboard`} className="navbar-brand">
              🛒 Super TV Advertisement
            </Link>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link to={`/dashboard`}>HOME</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="ab">
        <div className="ba">
          <div>
            <h1 className="dashboard text-center"></h1>
          </div>
        </div>
        <div id="www">
          <div>
            <div className="form-container">
              <form onSubmit={handleSubmit}>
                <h4 className="title">REGISTER USER</h4>
                <div className="mb-3">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="form-control"
                    placeholder="Enter User Name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <select
                    value={selectedStore}
                    onChange={(e) => setSelectedStore(e.target.value)}
                    className="form-control select-store"
                    required
                  >
                    <option value="">Select Store</option>
                    {address.map((store) => (
                      <option key={store._id} value={store.name}>
                        {store.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    placeholder="Enter User Email"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    value={phone}
                    onChange={handlePhoneChange}
                    className="form-control"
                    placeholder="Enter User Phone (Digits only)"
                    required
                  />
                  {phoneError && <p className="text-danger">{phoneError}</p>}
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    className="form-control"
                    placeholder="Enter User Password (8 characters or more)"
                    required
                  />
                  {passwordError && (
                    <p className="text-danger">{passwordError}</p>
                  )}
                </div>
                <button type="submit" id="l" className="btn btn-primary">
                  REGISTER
                </button>
                <Toaster />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterGetStore;
