import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import { useAuth } from "../context/auth.js";
import { Helmet } from "react-helmet";
import axios from "axios";

const Alluser = () => {
  const { slug } = useParams();
  const [storeName, setStoreName] = useState("");

  useEffect(() => {
    const fetchStoreName = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/store/single-store/${slug}`
        );
        if (data.success) {
          setStoreName(data.Store.name);
        } else {
          toast.error(data.message || "Failed to fetch store name");
        }
      } catch (error) {
        console.error(error);
        toast.error("Error fetching store name");
      }
    };

    fetchStoreName();
  }, [slug]);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/auth/allusers`,
          {
            params: {
              address: storeName, // Pass the storeName as address query parameter
            },
          }
        );
        if (response.data.success) {
          setUsers(response.data.users);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (storeName) {
      fetchUsers();
    }
  }, [storeName]);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API}/api/v1/auth/users/${id}`
      );

      if (response.data.success) {
        toast.success(response.message);
        setUsers(users.filter((user) => user._id !== id));
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const [auth, setAuth] = useAuth();
  const handlelogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout successfully");
  };
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    console.log("Toggle button clicked");
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <>
      <div>
        <Helmet>
          <title>All Users</title>
        </Helmet>
      </div>
      <div className="wrapper">
        {/* Sidebar */}
        <div
          id="sidebar"
          className={`sidebar ${isExpanded ? "sidebar-expanded" : ""}`}
          data-background-color="dark"
        >
          <div className="sidebar-logo">
            {/* Logo Header */}
            <div className="logo-header" data-background-color="dark">
              <Link href="#a" className="logo">
                <p id="mia">🛒 Super TV Advertisement</p>
              </Link>
              <div className="nav-toggle">
                <button className="btn btn-toggle toggle-sidebar">
                  <i className="gg-menu-right" />
                </button>
                <button
                  className="btn btn-toggle sidenav-toggler "
                  onClick={handleToggle}
                >
                  <i className={`gg-menu-${isExpanded ? "left" : "right"}`} />
                </button>
              </div>
              <button className="topbar-toggler more">
                <i className="gg-more-vertical-alt" />
              </button>
            </div>
            {/* End Logo Header */}
          </div>
          <div className="sidebar-wrapper scrollbar scrollbar-inner">
            <div className="sidebar-content">
              <ul className="nav nav-secondary">
                <li className="nav-item active">
                  <a href={`/enterdata/${slug}`}>
                    <i className="fas fa-home" />
                    <p>Dashboard</p>
                  </a>
                </li>
                <li className="nav-section">
                  <span className="sidebar-mini-icon">
                    <i className="fa fa-ellipsis-h" />
                  </span>
                  <h4 className="text-section">Components</h4>
                </li>
                <li className="nav-item">
                  <a data-bs-toggle="collapse" href="#base">
                    <i className="fas fa-layer-group" />
                    <p>Users</p>
                    <span className="caret" />
                  </a>
                  <div className="collapse" id="base">
                    <ul className="nav nav-collapse">
                      <li>
                        <a href="components/buttons.html">
                          <Link to={`/user/${slug}`}>Register User</Link>
                        </a>
                      </li>
                      <li>
                        <a href="components/buttons.html">
                          <Link to={`/alluser/${slug}`}>All User</Link>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <a href={`/store/${slug}`}>
                    <i className="fas fa-th-list" />
                    <NavLink to={`/store/${slug}`} onClick={handlelogout}>
                      LOGOUT
                    </NavLink>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Sidebar */}
        <div className="main-panel">
          <div className="main-header">
            <div className="main-header-logo">
              {/* Logo Header */}
              <div className="logo-header" data-background-color="dark">
                <a href="index.html" className="logo">
                  <img
                    src="assets/img/kaiadmin/logo_light.svg"
                    alt="navbar brand"
                    className="navbar-brand"
                    height={20}
                  />
                </a>
                <div className="nav-toggle">
                  <button className="btn btn-toggle toggle-sidebar">
                    <i className="gg-menu-right" />
                  </button>
                  <button
                    className="btn btn-toggle sidenav-toggler "
                    onClick={handleToggle}
                  >
                    <i className={`gg-menu-${isExpanded ? "left" : "right"}`} />
                  </button>
                </div>
                <button className="topbar-toggler more">
                  <i className="gg-more-vertical-alt" />
                </button>
              </div>
              {/* End Logo Header */}
            </div>
            {/* Navbar Header */}
            <nav className="navbar navbar-headers navbar-header-transparent navbar-expand-lg border-bottom">
              <div className="container-fluid">
                <nav className="navbar navbar-header-left navbar-expand-lg navbar-form nav-search p-0 d-none d-lg-flex">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button type="submit" className="btn btn-search pe-1">
                        <i className="fa fa-search search-icon" />
                      </button>
                    </div>
                    <input
                      type="text"
                      placeholder="Search ..."
                      className="form-control"
                    />
                  </div>
                </nav>
                <ul className="navbar-nav topbar-nav ms-md-auto align-items-center">
                  <li className="nav-item topbar-icon dropdown hidden-caret d-flex d-lg-none">
                    <a
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                      href="#a"
                      role="button"
                      aria-expanded="false"
                      aria-haspopup="true"
                    >
                      <i className="fa fa-search" />
                    </a>
                    <ul className="dropdown-menu dropdown-search animated fadeIn">
                      <form className="navbar-left navbar-form nav-search">
                        <div className="input-group">
                          <input
                            type="text"
                            placeholder="Search ..."
                            className="form-control"
                          />
                        </div>
                      </form>
                    </ul>
                  </li>

                  <li className="nav-item topbar-user ">
                    <Link
                      className="dropdown profile-pic"
                      href="#a"
                      aria-expanded="false"
                    >
                      <span className="profile-username">
                        <span className="op-7">Hi,</span>
                        <span className="bold">Admin</span>
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
            {/* End Navbar */}
          </div>
          <div className="container">
            <div className="page-inner">
              <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row pt-2 pb-4">
                <div>
                  <h3 className="fw-bold mb-3">Dashboard</h3>
                  <h6 className="op-7 mb-2">Welcome to {storeName}</h6>
                </div>
              </div>
              {/* ============================================================== */}

              {/* ============================================================== */}
              <div className="users-list">
                <h4>Registered Users : {users.length}</h4>

                <div className="card-containers">
                  {users.map((user) => (
                    <div className="cards" key={user._id}>
                      <h4>{user.name}</h4>
                      <p>Store Name: {user.address}</p>
                      <p>Email: {user.email}</p>
                      <p>Phone: {user.phone}</p>
                      <button onClick={() => handleDelete(user._id)}>
                        Delete
                      </button>
                      <Toaster />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <footer className="footer">
            <div className="container-fluid d-flex justify-content-between">
              <nav className="pull-left">
                <ul className="nav">
                  <li className="nav-item"></li>
                  <li className="nav-item">
                    <Link className="nav-link" href="#a">
                      {" "}
                      Help{" "}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" href="#a">
                      {" "}
                      Licenses{" "}
                    </Link>
                  </li>
                </ul>
              </nav>
              <div className="copyright">
                2024, made with <i className="fa fa-heart heart text-danger" />{" "}
                by
                <a href="https://digitalhelpltd.com/"> Digital Help Ltd</a>
              </div>
              <div>
                Distributed by
                <Link href="#a"> E Networks</Link>.
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Alluser;
