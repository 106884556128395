import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth.js";
import toast from "react-hot-toast";
import axios from "axios";
import { Helmet } from "react-helmet";
import AdminSideBar from "../../components/sidebar/AdminSideBar.js";
import Footer from "../../components/footer/Footer.jsx";
import NavbarHeader from "../../components/header/NavbarHeader.js";
import { TiUpload } from "react-icons/ti";
import "./style.css";

const Departimage = () => {
  const { storeSlug, departSlug } = useParams();
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(new Set());
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [imageCarouselInterval, setImageCarouselInterval] = useState(1500);
  const [videoCarouselInterval, setVideoCarouselInterval] = useState(3000);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [selectionOrder, setSelectionOrder] = useState(new Map());
  const [filePreviews, setFilePreviews] = useState([]);

  const fileInputRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Load intervals from local storage
    const storedImageInterval = localStorage.getItem("imageCarouselInterval");
    const storedVideoInterval = localStorage.getItem("videoCarouselInterval");

    if (storedImageInterval) {
      setImageCarouselInterval(Number(storedImageInterval));
    }
    if (storedVideoInterval) {
      setVideoCarouselInterval(Number(storedVideoInterval));
    }
  }, []);

  useEffect(() => {
    // Save intervals to local storage
    localStorage.setItem("imageCarouselInterval", imageCarouselInterval);
    localStorage.setItem("videoCarouselInterval", videoCarouselInterval);
  }, [imageCarouselInterval, videoCarouselInterval]);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/store/files/${storeSlug}/${departSlug}`
        );
        if (response.data.success) {
          setUploadedFiles(response.data.files);
        }
      } catch (error) {
        console.error("Error fetching files", error);
      }
    };

    fetchFiles();
  }, [storeSlug, departSlug]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
    setMessage("");

    const previews = selectedFiles.map((file) => URL.createObjectURL(file));
    setFilePreviews(previews);
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      alert("Please select at least one file");
      return;
    }

    const minImageSizeKB = 20;
    const maxImageSizeMB = 50;
    const minVideoSizeKB = 100;
    const maxVideoSizeMB = 500;

    const invalidImages = Array.from(files).filter(
      (file) =>
        file.type.startsWith("image/") &&
        (file.size < minImageSizeKB * 1024 ||
          file.size > maxImageSizeMB * 1024 * 1024)
    );

    const invalidVideos = Array.from(files).filter(
      (file) =>
        file.type.startsWith("video/") &&
        (file.size < minVideoSizeKB * 1024 ||
          file.size > maxVideoSizeMB * 1024 * 1024)
    );

    if (invalidImages.length > 0 || invalidVideos.length > 0) {
      let message = "";

      if (invalidImages.length > 0) {
        message += `Image size should be between ${
          minImageSizeKB / 1024
        } KB and ${maxImageSizeMB} MB. The following images have invalid sizes: ${invalidImages
          .map((file) => file.name)
          .join(", ")}. `;
      }

      if (invalidVideos.length > 0) {
        message += `Video size should be between ${
          minVideoSizeKB / 1024
        } KB and ${maxVideoSizeMB} MB. The following videos have invalid sizes: ${invalidVideos
          .map((file) => file.name)
          .join(", ")}. `;
      }

      setMessage(message.trim());
      setFiles([]);
      fileInputRef.current.value = "";
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    formData.append("storeSlug", storeSlug);
    formData.append("departSlug", departSlug);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/store/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      fileInputRef.current.value = "";
      if (response.data.success) {
        setMessage("Files uploaded successfully");
        setTimeout(() => {
          setMessage("");
        }, 3000);
        setUploadedFiles([...uploadedFiles, ...response.data.files]);
        setFiles([]);
        setFilePreviews([]);
      } else {
        setMessage("File upload failed");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          setMessage("Upload endpoint not found (404)");
        } else {
          setMessage(`File upload failed: ${error.response.status}`);
        }
      } else {
        setMessage("File upload failed");
      }
      console.error(error);
    }
  };

  const handleDelete = async () => {
    if (selectedFiles.size === 0) {
      alert("No files selected for deletion");
      return;
    }
    const directoryPath = "upload/";

    const selectedFilesArray = Array.from(selectedFiles).map((file) => ({
      filePath: file.filePath,
    }));
    const selectedFilesArrays = Array.from(selectedFiles).map((file) => ({
      filePath: `${directoryPath}${file.filePath}`,
    }));

    try {
      // Delete files from the frontend state
      const deleteResponse = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/store/deletes`,
        {
          storeSlug,
          departSlug,
          selectedFiles: selectedFilesArray,
        }
      );

      if (deleteResponse.data.success) {
        // Also delete files from the SelectedFile model

        const selectedFilesResponse = await axios.post(
          `${process.env.REACT_APP_API}/api/v1/store/delete`,
          {
            storeSlug,
            departSlug,
            selectedFiles: selectedFilesArrays,
          }
        );

        if (selectedFilesResponse.data.success) {
          setDeleteSuccess(true);
          setUploadedFiles((prev) =>
            prev.filter((file) => !selectedFiles.has(file))
          );
          window.location.reload();
          setSelectedFiles(new Set());
          setSelectionOrder(new Map());
          setTimeout(() => setDeleteSuccess(false), 3000);
        } else {
          setMessage(
            "Failed to delete selected files from the SelectedFile model"
          );
        }
      } else {
        setMessage("Failed to delete files from frontend");
      }
    } catch (error) {
      if (error.response) {
        setMessage(`Failed to delete selected files: ${error.response.status}`);
      } else {
        setMessage("Failed to delete selected files");
      }
      console.error(error);
    }
  };

  const handleCarouselIntervalChange = (e, type) => {
    const valueInMilliseconds = e.target.value * 1000;
    if (type === "images") {
      setImageCarouselInterval(valueInMilliseconds);
    } else if (type === "videos") {
      setVideoCarouselInterval(valueInMilliseconds);
    }
  };

  const handleDisplay = (fileType) => {
    navigate(
      `/displayfiles/${storeSlug}/${departSlug}?fileType=${fileType}&imageInterval=${imageCarouselInterval}&videoInterval=${videoCarouselInterval}`
    );
  };

  const handleSelectAlbum = (album) => {
    setSelectedAlbum(album);
    setSelectedFiles(new Set());
    setSelectionOrder(new Map());
  };

  const handleSelectFile = (file) => {
    setSelectedFiles((prev) => {
      const updated = new Set(prev);
      const newOrder = new Map(selectionOrder);

      console.log("Handling file:", file);
      console.log("Current selection:", Array.from(updated));
      console.log("Current order:", Array.from(newOrder.entries()));

      if (updated.has(file)) {
        updated.delete(file);
        newOrder.delete(file);
      } else {
        updated.add(file);
        newOrder.set(file, newOrder.size + 1);
      }

      // Renumber the remaining files
      let count = 1;
      newOrder.forEach((_, key) => {
        newOrder.set(key, count++);
      });

      // Log the updated state
      console.log("Updated selection:", Array.from(updated));
      console.log("Updated order:", Array.from(newOrder.entries()));

      setSelectionOrder(newOrder);
      return updated;
    });
  };
  const handleUploadSelected = async () => {
    if (selectedFiles.size === 0) {
      alert("No files selected");
      return;
    }

    const selectedFilesArray = Array.from(selectedFiles).map((file) => ({
      filePath: file.filePath,
    }));

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/store/addselected`,
        {
          storeSlug,
          departSlug,
          selectedFiles: selectedFilesArray,
        }
      );

      if (response.data.success) {
        setUploadSuccess(true);
        setTimeout(() => setUploadSuccess(false), 3000);
      } else {
        setMessage("Failed to upload selected files");
      }
    } catch (error) {
      if (error.response) {
        setMessage(`Failed to upload selected files: ${error.response.status}`);
      } else {
        setMessage("Failed to upload selected files");
      }
      console.error(error);
    }
  };

  const getAlbums = () => {
    const imageAlbums = {};
    const videoAlbum = [];

    uploadedFiles.forEach((file) => {
      if (file.filePath.match(/\.(jpg|jpeg|png)$/i)) {
        const album = file.album || "album";
        if (!imageAlbums[album]) {
          imageAlbums[album] = [];
        }
        imageAlbums[album].push(file);
      } else if (file.filePath.match(/\.(mp4|webm)$/i)) {
        videoAlbum.push(file);
      }
    });

    return { imageAlbums, videoAlbum };
  };

  const { imageAlbums, videoAlbum } = getAlbums();
  const imageCount = Object.values(imageAlbums).reduce(
    (acc, album) => acc + album.length,
    0
  );
  const videoCount = videoAlbum.length;

  return (
    <>
      <div>
        <Helmet>
          <title>Store-Upload/Display Ads</title>
        </Helmet>
      </div>
      <div className="wrapper">
        {/* ----------------- Sidebar --------------- */}
        <AdminSideBar />

        <div className="main-panel">
          <NavbarHeader />
          <div className="container">
            <div className="page-inner">
              <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row pt-2 pb-4">
                <div>
                  <h3 className="fw-bold mb-3">Dashboard</h3>
                  <h6 className="op-7 mb-2">
                    Upload images and videos in your department
                  </h6>
                </div>
              </div>
              {/* ============================================================== */}
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-round">
                    <div className="card-header">
                      <div className="card-head-row">
                        <div className="card-title">Upload Here</div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="formats-container">
                        <div className="format-card">
                          <div className="video-img-format">
                            <div className="format-heading">Image Format </div>
                            <div className="format-desc">
                              jpg | jpeg | png | svg
                            </div>
                          </div>
                          <div className="video-img-format">
                            <div className="format-heading">Image Size </div>
                            <div className="format-desc">
                              min 20 KB - max 50 MB
                            </div>
                          </div>
                        </div>
                        <div className="format-card">
                          <div className="video-img-format">
                            <div className="format-heading">Video Format </div>
                            <div className="format-desc">mp4 | webm</div>
                          </div>
                          <div className="video-img-format">
                            <div className="format-heading">Video Size </div>
                            <div className="format-desc">
                              min 100 KB - max 500 MB
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="chart-containr">
                        <div className="main-content">
                          <div className="upload-container">
                            <input
                              className="file-input"
                              id="file-upload"
                              type="file"
                              accept="image/*, video/*, .mp4, .webm, .jpg, .jpeg, .png, .svg"
                              multiple
                              onChange={handleFileChange}
                              ref={fileInputRef}
                            />
                            <label
                              htmlFor="file-upload"
                              className="file-input-label"
                            >
                              <TiUpload className="upload-icon" />
                              <br />
                              Upload Files
                            </label>

                            <div className="upload-actions">
                              <button
                                onClick={handleUpload}
                                className="upload-button"
                              >
                                Upload
                              </button>
                              {message && (
                                <p className="upload-message">{message}</p>
                              )}
                            </div>

                            {/* File Previews */}
                            <div className="file-previews">
                              {filePreviews.map((preview, index) => {
                                const file = files[index];
                                return (
                                  <div key={index} className="file-preview">
                                    {file.type.startsWith("image/") ? (
                                      <img
                                        src={preview}
                                        alt="Preview"
                                        className="preview-image"
                                      />
                                    ) : (
                                      <video
                                        src={preview}
                                        controls
                                        className="preview-video"
                                        width="100"
                                      />
                                    )}
                                    <button
                                      className="remove-file-button"
                                      onClick={() => {
                                        const newFiles = [...files];
                                        newFiles.splice(index, 1);
                                        setFiles(newFiles);

                                        const newPreviews = [...filePreviews];
                                        newPreviews.splice(index, 1);
                                        setFilePreviews(newPreviews);
                                      }}
                                    >
                                      X{/* &times; */}
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="set-time-interval">
                          <h2> Set Time Interval of Image and Video </h2>
                        </div>
                        <div className="set-time-interval">
                          <div className="text-centers">
                            <label htmlFor="image-carousel-interval">
                              Image Time Interval (seconds) :
                            </label>
                            <input
                              className="time-interval-input"
                              id="image-carousel-interval"
                              type="number"
                              value={
                                imageCarouselInterval
                                  ? imageCarouselInterval / 1000
                                  : ""
                              }
                              onChange={(e) =>
                                handleCarouselIntervalChange(e, "images")
                              }
                              min="1"
                              step="1"
                              onFocus={(e) => e.target.select()}
                            />
                          </div>
                          <div className="text-centerss">
                            <label htmlFor="video-carousel-interval">
                              Video Time Interval (seconds) :
                            </label>
                            <input
                              className="time-interval-input"
                              id="video-carousel-interval"
                              type="number"
                              value={
                                videoCarouselInterval
                                  ? videoCarouselInterval / 1000
                                  : ""
                              }
                              onChange={(e) =>
                                handleCarouselIntervalChange(e, "videos")
                              }
                              min="1"
                              step="1"
                              onFocus={(e) => e.target.select()}
                            />
                          </div>
                        </div>
                        <div className="display-buttons">
                          <button
                            onClick={() => handleDisplay("images")}
                            id="la"
                            className=" back-buttons"
                          >
                            DISPLAY IMAGES
                          </button>
                          <button
                            onClick={() => handleDisplay("videos")}
                            id="la"
                            className=" back-buttons"
                          >
                            DISPLAY VIDEOS
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ========================================================================== */}
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-round">
                    <div className="card-body">
                      <div
                        className={`albums-view ${
                          selectedAlbum ? "album-open" : ""
                        }`}
                      >
                        {!selectedAlbum ? (
                          <>
                            <div id="kki">
                              <div className="album-grid">
                                {Object.keys(imageAlbums).length === 0 ? (
                                  <p>No image albums available</p>
                                ) : (
                                  Object.keys(imageAlbums).map(
                                    (album, index) => (
                                      <div
                                        key={index}
                                        className="album-item"
                                        onClick={() => handleSelectAlbum(album)}
                                      >
                                        {imageAlbums[album][0] &&
                                          imageAlbums[album][0].filePath.match(
                                            /\.(jpg|jpeg|png|webp|tiff|bmp|svg|avif|apng)$/i
                                          ) && (
                                            <img
                                              src={`${process.env.REACT_APP_API}/upload/${imageAlbums[album][0].filePath}`}
                                              alt={`Album ${album}`}
                                              className="album-cover"
                                            />
                                          )}
                                      </div>
                                    )
                                  )
                                )}
                                <h4 className="album-grid-title">
                                  Photos ({imageCount})
                                </h4>
                              </div>

                              <div>
                                <div className="album-grid">
                                  {videoAlbum.length === 0 ? (
                                    <p>No video albums available</p>
                                  ) : (
                                    <div
                                      className="album-item"
                                      onClick={() =>
                                        handleSelectAlbum("Videos")
                                      }
                                    >
                                      <video className="album-cover" muted>
                                        <source
                                          src={`${process.env.REACT_APP_API}/upload/${videoAlbum[0].filePath}`}
                                          type={`video/${videoAlbum[0].filePath
                                            .split(".")
                                            .pop()}`}
                                        />
                                      </video>
                                    </div>
                                  )}
                                </div>
                                <h4 className="album-grid-title">
                                  Videos ({videoCount})
                                </h4>
                              </div>
                            </div>
                          </>
                        ) : selectedAlbum === "Videos" ? (
                          <>
                            {videoAlbum.length === 0 ? (
                              <p>No videos available</p>
                            ) : (
                              <div className="gallery">
                                {videoAlbum.map((file, index) => (
                                  <div key={index} className="gallery-item">
                                    <input
                                      type="checkbox"
                                      onChange={() => handleSelectFile(file)}
                                      checked={selectedFiles.has(file)}
                                    />
                                    {selectionOrder.has(file) && (
                                      <span id="che">
                                        {selectionOrder.get(file)}
                                      </span>
                                    )}
                                    {file.filePath.match(
                                      /\.(mp4|webm|ogg|avi|mov|wmv|mkv|flv|avchd|vob|mng|3gp|hdtv)$/i
                                    ) ? (
                                      <video controls>
                                        <source
                                          src={`${process.env.REACT_APP_API}/upload/${file.filePath}`}
                                          type={`video/${file.filePath
                                            .split(".")
                                            .pop()}`}
                                        />
                                        {console.log(
                                          "file.filePath",
                                          file.filePath
                                        )}
                                        Your browser does not support the video
                                        tag.
                                      </video>
                                    ) : (
                                      <p>Unsupported file type</p>
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}

                            <button
                              onClick={handleUploadSelected}
                              className="back-button"
                            >
                              Upload Selected
                            </button>
                            <button
                              onClick={handleDelete}
                              id="omz"
                              className="back-button"
                            >
                              Delete Selected
                            </button>
                            {deleteSuccess && (
                              <div className="alert alert-success" role="alert">
                                Delete successfully
                              </div>
                            )}
                            {uploadSuccess && (
                              <div className="alert alert-success" role="alert">
                                Upload successfully
                              </div>
                            )}
                            <button
                              id="omy"
                              onClick={() => setSelectedAlbum(null)}
                              className="back-button"
                            >
                              Back to Albums
                            </button>
                          </>
                        ) : (
                          <>
                            {imageAlbums[selectedAlbum].length === 0 ? (
                              <p>No images available in this album</p>
                            ) : (
                              <div className="gallery">
                                {imageAlbums[selectedAlbum].map(
                                  (file, index) => (
                                    <div key={index} className="gallery-item">
                                      <input
                                        type="checkbox"
                                        onChange={() => handleSelectFile(file)}
                                        checked={selectedFiles.has(file)}
                                      />
                                      {selectionOrder.has(file) && (
                                        <span id="che">
                                          {selectionOrder.get(file)}
                                        </span>
                                      )}
                                      {file.filePath.match(
                                        /\.(jpg|jpeg|png|webp|tiff|bmp|avif|svg|apng)$/i
                                      ) ? (
                                        <img
                                          src={`${process.env.REACT_APP_API}/upload/${file.filePath}`}
                                          alt={`File ${index}`}
                                        />
                                      ) : (
                                        <p>Unsupported file format</p>
                                      )}
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                            <button
                              onClick={handleUploadSelected}
                              className="back-button"
                            >
                              Upload Selected
                            </button>
                            <button
                              onClick={handleDelete}
                              id="omz"
                              className="back-button"
                            >
                              Delete Selected
                            </button>
                            {deleteSuccess && (
                              <div className="alert alert-success" role="alert">
                                Delete Successfully
                              </div>
                            )}
                            {uploadSuccess && (
                              <div className="alert alert-success" role="alert">
                                Upload Successfully
                              </div>
                            )}
                            <button
                              id="omy"
                              onClick={() => setSelectedAlbum(null)}
                              className="back-button"
                            >
                              Back to Albums
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Departimage;
