import React, { useState } from "react";

import Marquee from "react-fast-marquee";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Toaster } from "react-hot-toast";

// import { NavLink } from 'react-router-dom';
import imgs2 from "../images/imgs2.jpg";
import imgs5 from "../images/imgs5.jpg";
import imgs3 from "../images/imgs3.jpg";
import imgs1 from "../images/imgs1.webp";
import imgs4 from "../images/imgs4.jpg";
const HomePage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/api/v1/form/forms`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      console.log("API RES:", response);

      if (response.ok) {
        alert("Your message has been sent. Thank you!");
        setFormData({ name: "", email: "", subject: "", message: "" });
      } else {
        alert("Failed to send message. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <div>
        <Helmet>
          <title>Supertv</title>
        </Helmet>
      </div>
      <div>
        <header
          id="header"
          className="headerzz d-flex align-items-center fixed-top"
        >
          <div className="container-fluid container-xl position-relative d-flex align-items-center">
            <a href="/" className="logo d-flex align-items-center me-auto">
              {/* Uncomment the line below if you also wish to use an image logo */}
              {/* <img src="assets/img/logo.png" alt=""> */}
              <h1 className="sitename">SUPER TV</h1>
            </a>
            <nav id="navmenu" className="navmenu">
              <ul>
                <li>
                  <a href="#hero" className="active">
                    Home
                  </a>
                </li>

                <li>
                  <a href="#services">Our ADVERTISMENTS</a>
                </li>

                <li>
                  <a href="#contact">Contact</a>
                </li>
              </ul>
              <i className="mobile-nav-toggle d-xl-none bi bi-list" />
            </nav>
          </div>
        </header>
        <main className="main">
          {/* Hero Section */}
          <section id="hero" className="hero section dark-background">
            <img src="assetsz/img/hero-bg.jpg" alt="" data-aos="fade-in" />
            <div className="container d-flex flex-column align-items-center">
              <h2 data-aos="fade-up" data-aos-delay={100}>
                PLAN. LAUNCH. GROW.
              </h2>
              <p data-aos="fade-up" data-aos-delay={200}>
                AN ADVERTISING AND DIGITAL AGENCY WITH A PASSION FOR MARKETING
              </p>
              <div
                className="d-flex mt-4"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <a href="#contact" className="btn-get-started">
                  BECOME OUR MEMBER
                </a>
              </div>
            </div>
          </section>
          {/* /Hero Section */}
          {/* About Section */}

          {/* Services Section */}

          {/* Services 2 Section */}
          <section
            id="services"
            className="services-2 section light-background"
          >
            {/* Section Title */}
            <div className="container section-title" data-aos="fade-up">
              <h2>Services</h2>
              <p>CHECK OUR ADVERTISMENT</p>
            </div>
            {/* End Section Title */}
            <div className="container">
              <div className="row gy-4" id="ws">
                <Marquee>
                  <div className="im">
                    <img src={imgs2} alt="" />
                  </div>
                  <div className="im">
                    <img src={imgs5} alt="" />
                  </div>
                  <div className="im">
                    <img src={imgs3} alt="" />
                  </div>
                  <div className="im">
                    <img src={imgs1} alt="" />
                  </div>
                  <div className="im">
                    <img src={imgs4} alt="" />
                  </div>
                </Marquee>
              </div>
            </div>
          </section>
          {/* /Services 2 Section */}

          {/* Contact Section */}
          <section id="contact" className="contact section">
            {/* Section Title */}
            <div className="container section-title" data-aos="fade-up">
              <h2>Contact</h2>
              <p>Contact Us for membership</p>
            </div>
            {/* End Section Title */}
            <div className="container" data-aos="fade-up" data-aos-delay={100}>
              <div className="row gy-4">
                <div className="col-lg-4">
                  <div className="row gy-4">
                    {/* <div className="col-lg-12">
                      <div
                        className="info-item d-flex flex-column justify-content-center align-items-center"
                        data-aos="fade-up"
                        data-aos-delay={200}
                      >
                        <i className="bi bi-geo-alt" />
                        <h3>Address</h3>
                        <p>A108 Adam Street, New York, NY 535022</p>
                      </div>
                    </div> */}
                    {/* End Info Item */}
                    <div className="col-md-12">
                      <div
                        className="info-item d-flex flex-column justify-content-center align-items-center"
                        data-aos="fade-up"
                        data-aos-delay={300}
                      >
                        {/* <h3> For More Details You Can Contact</h3> */}
                        <i className="bi bi-telephone" />
                        <h3>Call Us</h3>
                        <p style={{ fontWeight: "600" }}>+46 72 277 59 59</p>
                      </div>
                    </div>
                    {/* End Info Item */}
                    <div className="col-md-12">
                      <div
                        className="info-item d-flex flex-column justify-content-center align-items-center"
                        data-aos="fade-up"
                        data-aos-delay={400}
                      >
                        <i className="bi bi-envelope" />
                        <h3>Email Us</h3>
                        <p>info@supertv.se</p>
                      </div>
                    </div>
                    {/* End Info Item */}
                  </div>
                </div>
                <div className="col-lg-8">
                  <form onSubmit={handleSubmit} className="php-email-form">
                    <div className="row gy-4">
                      <div className="col-md-6">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Your Name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Your Email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-md-12">
                        <input
                          type="text"
                          className="form-control"
                          name="subject"
                          placeholder="Subject"
                          value={formData.subject}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-md-12">
                        <textarea
                          className="form-control"
                          name="message"
                          rows={4}
                          placeholder="Message"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-md-12 text-center">
                        <button type="submit">Send Message</button>
                        <Toaster />
                      </div>
                    </div>
                  </form>
                </div>
                {/* End Contact Form */}
              </div>
            </div>
          </section>
          {/* /Contact Section */}

          {/* <div className="col-md-12 text-center mt-4">
            <a
              href="https://wa.me/+923052690480?text=Hello!%20I%20would%20like%20to%20know%20more%20about%20your%20services."
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp-fixed"
              id="osms"
            >
              <FontAwesomeIcon icon={faWhatsapp} size="2x" />
            </a>
          </div> */}
        </main>
        <footer id="footersz" className="footersz dark-background">
          <div className="container copyright text-center mt-4">
            <p>
              © <span>Copyright</span>{" "}
              <strong className="px-1 sitename">SUPER TV</strong>{" "}
              <span>All Rights Reserved</span>
            </p>
            <div className="credits">
              {/* All the links in the footer should remain intact. */}
              {/* You can delete the links only if you've purchased the pro version. */}
              {/* Licensing information: https://bootstrapmade.com/license/ */}
              {/* Purchase the pro version with working PHP/AJAX contact form: [buy-url] */}
              Designed by{" "}
              <a href="https://digitalhelpltd.com/">Digital Help Ltd</a>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default HomePage;
