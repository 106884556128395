import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useAuth } from "../../context/auth";
import { Helmet } from "react-helmet";

const Storedata = () => {
  const { slug } = useParams();
  const [storeName, setStoreName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStoreName = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/store/single-store/${slug}`
        );
        if (data.success) {
          setStoreName(data.Store.name);
        } else {
          toast.error(data.message || "Failed to fetch store name");
        }
      } catch (error) {
        console.error(error);
        toast.error("Error fetching store name");
      }
    };

    fetchStoreName();
  }, [slug]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/auth/logins`,
        { email, password, role: 0, address: storeName }
      );

      if (res && res.data.success) {
        toast.success(res.data.message || "Login successful");
        setAuth({
          ...auth,
          user: res.data.user,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        navigate(`/enterdata/${slug}`);
      } else {
        toast.error(res.data.message || "Login failed");
      }
    } catch (error) {
      // Handle the error properly
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
      console.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Your Store - Login</title>
      </Helmet>

      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <Link className="navbar-brand">🛒 Super TV Advertisement</Link>
          </div>
        </div>
      </nav>

      <div className="ab">
        <div className="ba">
          <h1 className="dashboard text-center">Welcome to {storeName}</h1>
        </div>
        <div className="form-containers">
          <form id="m" onSubmit={handleSubmit}>
            <h4 className="title">LOGIN STORE ADMIN</h4>
            <div className="mb-3">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                placeholder="Enter Your Email"
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-control"
                placeholder="Enter Your Password"
                required
              />
            </div>
            <button type="submit" id="l" className="btn btn-primary">
              LOGIN
            </button>
            <Toaster />
          </form>
        </div>
      </div>
    </>
  );
};

export default Storedata;
