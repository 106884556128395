import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useAuth } from "../../context/auth";
import { Helmet } from "react-helmet";
import LogoComponent from "../../components/overview/LogoComponent";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/auth/login`,
        {
          email,
          password,
          role: 1,
        }
      );

      if (res && res.data.success) {
        toast.success(res.data.message);
        setAuth({
          ...auth,
          user: res.data.user,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        navigate("/dashboard");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      // Handle the error properly
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
      console.error(error);
    }
  };

  return (
    <>
      <div>
        <Helmet>
          <title>SuperTV-Login</title>
        </Helmet>
      </div>

      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link to={`/`}>HOME</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="ab">
        <div className="ba">
          <div>
            <h1 className="dashboard text-center"> </h1>
          </div>
        </div>
        <LogoComponent />
        <div className="form-containers">
          <form onSubmit={handleSubmit}>
            <h4 className="title">LOGIN SUPER ADMIN</h4>
            <div className="mb-3">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                placeholder="Enter Your Email"
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-control"
                placeholder="Enter Your Password"
                required
              />
            </div>
            <button type="submit" id="l" className="btn btn-primary">
              LOGIN
            </button>
            <Toaster />
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
