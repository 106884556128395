import React, { useState, useEffect, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import { useAuth } from "../../context/auth.js";
import { Helmet } from "react-helmet";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Storeform from "../../components/form/Storeform.js";
import Modal from "antd/es/modal/Modal.js";
import "../../App.css";

import io from "socket.io-client";
import Footer from "../../components/footer/Footer.jsx";
import NavbarHeader from "../../components/header/NavbarHeader.js";
const API_URL = process.env.REACT_APP_API || "http://localhost:7000";

const Dashboard = () => {
  const [stores, setStores] = useState([]);
  const [name, setName] = useState("");
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [updatedName, setUpdatedName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [storesPerPage] = useState(10); // Number of stores per

  const [notifications, setNotifications] = useState([]);
  const [showAll, setShowAll] = useState(false); // To toggle between 5 and all notifications

  const notifListRef = useRef(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/notifications`
        );
        console.log("Fetched notifications:", response.data); // Debugging
        setNotifications(response.data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();

    const socket = io(API_URL);
    socket.on("newUserNotification", (notif) => {
      console.log("New notification:", notif); // Debugging
      setNotifications((prevNotifications) => [...prevNotifications, notif]);
    });

    return () => {
      socket.off("newUserNotification");
    };
  }, []);

  useEffect(() => {
    if (showAll && notifListRef.current) {
      notifListRef.current.scrollTop = notifListRef.current.scrollHeight;
    }
  }, [showAll, notifications]);

  const displayedNotifications = showAll
    ? notifications
    : notifications.slice(0, 4);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/store/create-store`,
        { name }
      );
      if (data?.success) {
        toast.success(`${name} is Created`);
        getAllStores();
        setName("");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      // Handle the error properly
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
      console.error(error);
    }
  };

  const getAllStores = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/store/stores-with-departments`
      );
      if (data.success) {
        setStores(data.stores);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in getting Stores");
    }
  };

  useEffect(() => {
    getAllStores();
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API}/api/v1/store/update-store/${selected._id}`,
        { name: updatedName }
      );
      if (data.success) {
        toast.success(data.message);
        setSelected(null);
        setUpdatedName("");
        setVisible(false);
        getAllStores();
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const handleDelete = async (pId) => {
    try {
      let answer = window.prompt(
        "Are You Sure Want To Delete This Store ? say yes"
      );
      if (!answer) return;
      const { data } = await axios.delete(
        `${process.env.REACT_APP_API}/api/v1/store/delete-store/${pId}`
      );
      if (data.success) {
        toast.success(data.message);
        getAllStores();
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const [auth, setAuth] = useAuth();
  const handlelogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout successfully");
  };

  // Logic for pagination
  const indexOfLastStore = currentPage * storesPerPage;
  const indexOfFirstStore = indexOfLastStore - storesPerPage;
  const currentStores = stores.slice(indexOfFirstStore, indexOfLastStore);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    console.log("Toggle button clicked");
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <>
      <div>
        <Helmet>
          <title>Admin-Dashboard</title>
        </Helmet>
      </div>
      <div className="wrapper">
        {/* Sidebar */}
        <div
          id="sidebar"
          className={`sidebar ${isExpanded ? "sidebar-expanded" : ""}`}
          data-background-color="dark"
        >
          <div className="sidebar-logo">
            {/* Logo Header */}
            <div className="logo-header" data-background-color="dark">
              <Link to="/" className="logo">
                <p id="mia">🛒 Super TV Advertisement</p>
              </Link>
              <div className="nav-toggle">
                <button className="btn btn-toggle toggle-sidebar">
                  <i className={`gg-menu-${isExpanded ? "left" : "right"}`} />
                </button>
                <button
                  className="btn btn-toggle sidenav-toggler "
                  onClick={handleToggle}
                >
                  <i className={`gg-menu-${isExpanded ? "left" : "right"}`} />
                </button>
              </div>
              <button className="topbar-toggler more">
                <i className="gg-more-vertical-alt" />
              </button>
            </div>
            {/* End Logo Header */}
          </div>
          <div className="sidebar-wrapper scrollbar scrollbar-inner">
            <div className="sidebar-content">
              <ul className="nav nav-secondary">
                <li className="nav-item active">
                  <a href="/dashboard">
                    <i className="fas fa-home" />
                    <p>Dashboard</p>
                  </a>
                </li>
                <li className="nav-section">
                  <span className="sidebar-mini-icon">
                    <i className="fa fa-ellipsis-h" />
                  </span>
                  <h4 className="text-section">Components</h4>
                </li>
                <li className="nav-item">
                  <a data-bs-toggle="collapse" href="#base">
                    <i className="fas fa-layer-group" />
                    <p>Users</p>
                    <span className="caret" />
                  </a>
                  <div className="collapse" id="base">
                    <ul className="nav nav-collapse">
                      <li>
                        <a href="components/buttons.html">
                          <Link to={`/registerStore`}>Register User</Link>
                        </a>
                      </li>
                      <li>
                        <a href="components/buttons.html">
                          <Link to={`/temp`}>All User</Link>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <a href="/message">
                    <i className="bi bi-envelope" />
                    <Link to="/message">All Message</Link>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/">
                    <i className="fas fa-th-list" />
                    <NavLink onClick={handlelogout} to="/">
                      LOGOUT
                    </NavLink>
                    <Toaster />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Sidebar */}
        <div className="main-panel">
          <NavbarHeader />
          <div className="container">
            <div className="page-inner">
              <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row pt-2 pb-4">
                <div>
                  <h3 className="fw-bold mb-3">Dashboard</h3>
                  <h6 className="op-7 mb-2">Welcome to Admin Dashboard</h6>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="card card-round">
                    <div className="card-body">
                      <div className="card-head-row card-tools-still-right">
                        <div className="card-title">Create Stores</div>
                        <div className="card-tools">
                          <div className="dropdown">
                            <button
                              className="btn btn-icon btn-clean me-0"
                              type="button"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            ></button>
                          </div>
                        </div>
                      </div>
                      <div className="card-list py-4">
                        <div className="item-list">
                          <div className="info-user ms-3">
                            <Storeform
                              handleSubmit={handleSubmit}
                              value={name}
                              setValue={setName}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card card-round">
                    <div className="card-body">
                      <div className="card-head-row card-tools-still-right">
                        <div className="card-title">Total Stores</div>
                        <div className="card-tools">
                          <div className="dropdown">
                            <button
                              className="btn btn-icon btn-clean me-0"
                              type="button"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            ></button>
                          </div>
                        </div>
                      </div>
                      <div className="card-list ">
                        <div className="item-list">
                          <div className="info-user ">
                            <p id="kis">{stores.length}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="card card-round">
                    <div className="card-header">
                      <div className="card-head-row card-tools-still-right">
                        <div className="card-title">Store Details</div>
                        <div className="card-tools">
                          <div className="dropdown">
                            <button
                              className="btn btn-icon btn-clean me-0"
                              type="button"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table align-items-center mb-0">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Created Date</th>
                              <th scope="col">Your Stores</th>
                              <th scope="col">Total Departments</th>{" "}
                              {/* New Column */}
                              <th scope="col">Images</th>
                              <th scope="col">Videos</th>
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentStores.map((s, index) => (
                              <tr key={s._id}>
                                <td id="koor">
                                  {index +
                                    1 +
                                    (currentPage - 1) * storesPerPage}
                                </td>
                                <td id="koor">
                                  {new Date(s.createdAt).toLocaleDateString()}
                                </td>
                                <td className="li store-name">
                                  <NavLink to={`/store/${s.slug}`}>
                                    {s.name}
                                  </NavLink>
                                </td>
                                <td id="koor">{s.departmentCount}</td>{" "}
                                {/* Displaying Department Count */}
                                <td id="koor">{s.totalImageCount}</td>
                                <td id="koor">{s.totalVideoCount}</td>
                                <td>
                                  <button className="btn btn-secondary ms-2">
                                    <NavLink
                                      to={`/store/${s.slug}`}
                                      style={{ color: "white" }}
                                    >
                                      Visit Store
                                    </NavLink>
                                  </button>
                                  <button
                                    className="btn btn-primary ms-2"
                                    onClick={() => {
                                      setVisible(true);
                                      setUpdatedName(s.name);
                                      setSelected(s);
                                    }}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    className="btn btn-danger ms-2"
                                    onClick={() => {
                                      handleDelete(s._id);
                                    }}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <nav>
                          <ul className="pagination justify-content-center">
                            {[
                              ...Array(
                                Math.ceil(stores.length / storesPerPage)
                              ).keys(),
                            ].map((number) => (
                              <li
                                key={number}
                                className={`page-item ${
                                  currentPage === number + 1 ? "active" : ""
                                }`}
                              >
                                <button
                                  onClick={() => paginate(number + 1)}
                                  className="page-link"
                                >
                                  {number + 1}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </div>
                    </div>
                    <Modal
                      onCancel={() => setVisible(false)}
                      footer={null}
                      visible={visible}
                    >
                      <Storeform
                        value={updatedName}
                        setValue={setUpdatedName}
                        handleSubmit={handleUpdate}
                      />
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
