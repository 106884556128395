import React, { useState } from "react";
import { Link } from "react-router-dom";

const NavbarHeader = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    console.log("Toggle button clicked");
    setIsExpanded((prevState) => !prevState);
  };
  return (
    <>
      <div className="main-header">
        <div className="main-header-logo">
          {/* Logo Header */}
          <div className="logo-header" data-background-color="dark">
            <a href="index.html" className="logo">
              <img
                src="assets/img/kaiadmin/logo_light.svg"
                alt="navbar brand"
                className="navbar-brand"
                height={20}
              />
            </a>
            <div className="nav-toggle">
              <button className="btn btn-toggle toggle-sidebar">
                <i className="gg-menu-right" />
              </button>
              <button
                className="btn btn-toggle sidenav-toggler "
                onClick={handleToggle}
              >
                <i className={`gg-menu-${isExpanded ? "left" : "right"}`} />
              </button>
            </div>
            <button className="topbar-toggler more">
              <i className="gg-more-vertical-alt" />
            </button>
          </div>
          {/* End Logo Header */}
        </div>
        {/* Navbar Header */}
        <nav className="navbar navbar-headers navbar-header-transparent navbar-expand-lg border-bottom">
          <div className="container-fluid">
            <nav className="navbar navbar-header-left navbar-expand-lg navbar-form nav-search p-0 d-none d-lg-flex">
              <div className="input-group">
                <div className="input-group-prepend">
                  <button type="submit" className="btn btn-search pe-1">
                    <i className="fa fa-search search-icon" />
                  </button>
                </div>
                <input
                  type="text"
                  placeholder="Search ..."
                  className="form-control"
                />
              </div>
            </nav>
            <ul className="navbar-nav topbar-nav ms-md-auto align-items-center">
              <li className="nav-item topbar-icon dropdown hidden-caret d-flex d-lg-none">
                <a
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  href="#a"
                  role="button"
                  aria-expanded="false"
                  aria-haspopup="true"
                >
                  <i className="fa fa-search" />
                </a>
                <ul className="dropdown-menu dropdown-search animated fadeIn">
                  <form className="navbar-left navbar-form nav-search">
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Search ..."
                        className="form-control"
                      />
                    </div>
                  </form>
                </ul>
              </li>

              <li className="nav-item topbar-user ">
                <Link
                  className="dropdown profile-pic"
                  href="#a"
                  aria-expanded="false"
                >
                  <span className="profile-username">
                    <span className="op-7">Hi,</span>
                    <span className="bold">Admin</span>
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
        {/* End Navbar */}
      </div>
    </>
  );
};

export default NavbarHeader;
