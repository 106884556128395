import { Routes, Route } from "react-router-dom";
import Homepage from "./pages/homepage";
// import Register from "./pages/Auth/Register";
import Login from "./pages/Auth/Login";
import Dashboard from "./pages/user/Dashboard";
import PrivateRoute from "./components/routes/private.js";
import Adminroutess from "./components/routes/Adminroutess.js";
import Privateroutess from "./components/routes/Privateroutess.js"; // New private route component
import Storedata from "./pages/stores/Storedata";
import RegisterStore from "./pages/stores/RegisterGetStore.js";
import LoginGetStore from "./pages/stores/LoginGetStore";
import EnterStoreData from "./pages/stores/EnterStoreData";
import Departimage from "./pages/stores/Departimage.js";
import DisplayFiles from "./pages/stores/DisplayFiles";
import Temp from "./pages/Temp.js";
import Privateroutesss from "./components/routes/Privateroutesss";
import Storeuser from "./pages/stores/Storeuser.js";
import Alluser from "./pages/Alluser.js";
import Message from "./pages/user/Message.js";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/temp" element={<Temp />} />
        <Route path="/alluser/:slug" element={<Alluser />} />
        <Route path="/store/:slug" element={<Storedata />} />
        <Route path="/user/:slug" element={<Storeuser />} />
        <Route
          path="/displayfiles/:storeSlug/:departSlug"
          element={<DisplayFiles />}
        />
        <Route path="/registerStore" element={<RegisterStore />} />
        <Route path="/loginStore/:slug" element={<LoginGetStore />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/message" element={<Message />} />

        {/* User private routes */}
        <Route path="/dashboard" element={<PrivateRoute adminOnly={true} />}>
          <Route path="" element={<Dashboard />} />
        </Route>

        {/* Admin private routes */}
        <Route path="/admin/*" element={<Adminroutess />}>
          <Route path="dashboard" element={<Dashboard />} />
          {/* Add more admin routes here */}
        </Route>

        {/* Role-based private routes */}
        <Route path="/enterdata/:slug" element={<Privateroutess />}>
          <Route path="" element={<EnterStoreData />} />
          {/* Add user-specific routes here */}
        </Route>
        <Route
          path="/departimage/:storeSlug/:departSlug"
          element={<Privateroutesss />}
        >
          <Route path="" element={<Departimage />} />

          {/* Add user-specific routes here */}
        </Route>
      </Routes>
    </>
  );
}

export default App;
