import React from 'react'

function Storeform({handleSubmit,value,setValue}) {
  return (
    <>
        <form onSubmit={handleSubmit}>
            <div className='mb-2 w-75 ms-5'>
                <input type='text' className='form-control' placeholder='Enter New Store' value={value} onChange={(e)=> setValue(e.target.value)}/>
            </div>
            <button type='submit' className='btn1 btn-primary'>Create</button>
           
        </form>
    </>
  )
}


export default Storeform